import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/address_dialog";
import Services from "../../../Services/Services";

export default class AddressDialog extends Component {
  id = "address-dialog";

  template = templateDefault;

  default_address = {
    first_name: "",
    last_name: "",
    street: "",
    city: "",
    postal_code: "",
    street_number: "",
    phone: "",
    state: "",
    country: "",
    notes: "",
  };

  onLoad(data) {
    super.onLoad(data);
    var open = this.props.open;

    this.setData({
      "default.open": open,
      "default.address": { ...this.default_address },
    });
  }

  selectAddress(id) {
    Services.get("address").then(([addressService]) => {
      addressService
        .getAddress(id)
        .then((addressService) => {
          this.setData({
            "default.address": addressService.getData("address", {
              ...this.default_address,
            }),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  resetAddress() {
    this.setData({
      "default.address": { ...this.default_address },
    });
    this.deleteData("error");
  }

  addAddress(e) {
    e.preventDefault();

    const { address } = this.getData("default", { ...this.default_address });

    const addressId = address._id;
    const onUpdate = addressId ? true : false;

    const error = {
      address: this.getHelpers("validate").validate(address, {
        first_name: [
          {
            rule: "required",
            message: this.ucfirst("first_name-helpertext"),
          },
        ],
        last_name: [
          {
            rule: "required",
            message: this.ucfirst("last_name-helpertext"),
          },
        ],
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
        ],
        street: [
          {
            rule: "required",
            message: this.ucfirst("street_address-helpertext"),
          },
        ],
        // street_number: [
        //   {
        //     rule: "required",
        //     message: this.ucfirst("street_number-helpertext"),
        //   },
        // ],
        city: [
          {
            rule: "required",
            message: this.ucfirst("city-helpertext"),
          },
        ],
        postal_code: [
          {
            rule: "required",
            message: this.ucfirst("postal_code-helpertext"),
          },
        ],
        state: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
        ],
        country: [
          {
            rule: "required",
            message: this.ucfirst("country-helpertext"),
          },
        ],
      }),
    };

    this.setData({
      error,
    });

    if (!error.address) {
      address.postal_code = address.postal_code.trim();
      address.address = `${address.street} ${address.city},${address.state} ${address.postal_code}, ${address.country}`;

      Services.get("address").then(([addressService]) => {
        if (onUpdate) {
          addressService
            .updateAddress(addressId, address)
            .then(() => {
              this.props.retrieveAddresses();
              this.props.setOpen(false);
              this.resetAddress();
            })
            .catch((err) => {
              console.log("error", err);
            });
        } else {
          addressService
            .createAddress(address)
            .then(() => {
              this.props.retrieveAddresses();
              this.props.setOpen(false);
              this.resetAddress();
            })
            .catch((err) => {
              console.log("error", err);
            });
        }
      });
    }
  }

  initGoogleAutocomplete() {
    setTimeout(() => {
      const inputElement = document.getElementById("autocomplete");
      if (!inputElement) return;

      const autocomplete = new window.google.maps.places.Autocomplete(
        inputElement,
        {
          types: ["address"],
          componentRestrictions: { country: "US" },
          fields: ["address_components", "geometry"],
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) return;

        const addressComponents = this.parseAddressComponents(
          place.address_components
        );

        const {
          street,
          city,
          state,
          country,
          postal_code: postalCode,
          street_number: streetNumber,
        } = addressComponents;

        const { address } = this.getData("default", {
          ...this.default_address,
        });
        this.deleteData("error");

        // Build the address object
        address.street = `${streetNumber} ${street}`.trim();
        address.street_number = streetNumber;
        address.city = city || "";
        address.postal_code = postalCode || "";
        address.state = state || "";
        address.country = country || "";
        address.typed_text = "";

        // Validate the address
        const error = {
          address: this.getHelpers("validate").validate(address, {
            street: [
              {
                rule: "required",
                message: this.ucfirst("street_address-helpertext"),
              },
            ],
            street_number: [
              {
                rule: "required",
                message: this.ucfirst("street_number-helpertext"),
              },
            ],
            city: [
              {
                rule: "required",
                message: this.ucfirst("city-helpertext"),
              },
            ],
            postal_code: [
              {
                rule: "required",
                message: this.ucfirst("postal_code-helpertext"),
              },
            ],
            state: [
              {
                rule: "required",
                message: this.ucfirst("phone-helpertext"),
              },
            ],
            country: [
              {
                rule: "required",
                message: this.ucfirst("country-helpertext"),
              },
            ],
          }),
        };

        // If valid, update the address in the state
        this.setData({
          "default.address": address,
          error,
        });
      });
    }, 500);
  }

  // Helper function to parse address components
  parseAddressComponents(components) {
    const result = {
      street: "",
      street_number: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
    };

    components.forEach((component) => {
      const type = component.types[0];

      switch (type) {
        case "street_number":
          result.street_number = component.long_name;
          break;
        case "route":
          result.street = component.short_name;
          break;
        case "postal_code":
          result.postal_code = component.long_name;
          break;
        case "postal_code_suffix":
          result.postal_code += `-${component.long_name}`;
          break;
        case "locality":
          result.city = component.short_name;
          break;
        case "sublocality_level_1":
          result.city = result.city || component.short_name;
          break;
        case "administrative_area_level_1":
          result.state = component.long_name;
          break;
        case "country":
          result.country = component.short_name;
          break;
        default:
          break;
      }
    });

    return result;
  }
}
