import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddressForm from "./address_form";

const SubmitBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  borderRadius: 0,
  border: "1px solid #000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
}));

const MyDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": { padding: theme.spacing(2) },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-rounded": {
    borderRadius: 0,
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Address(props) {
  const { component } = props;
  const { open = false } = component.props;
  const onSubmit = component.getData("onSubmit", true);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <MyDialog
      onClose={() => {
        component.props.setOpen(false);
        component.resetAddress();
      }}
      open={open}
      maxWidth="md"
      fullScreen={fullScreen}
    >
      <MyDialogTitle
        onClose={() => {
          component.props.setOpen(false);
          component.resetAddress();
        }}
      >
        {component.ucfirst("add-new-address-dialog-title")}
      </MyDialogTitle>
      <DialogContent dividers>
        <form onSubmit={(e) => component.addAddress(e)}>
          <AddressForm
            {...{
              ...props,
              ...{
                type: "address",
              },
            }}
          />
          <Grid
            container
            direction="row"
            justifycontent="center"
            alignItems="center"
            spacing={1}
            sx={{ marginTop: "2px" }}
          >
            <Grid item xs={12}>
              <SubmitBtn
                type={onSubmit ? "submit" : "button"}
                fullWidth
                onClick={(e) => {
                  if (!onSubmit) {
                    component.addAddress(e);
                  }
                }}
              >
                {component.ucfirst("submit-btn")}
              </SubmitBtn>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </MyDialog>
  );
}
