import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Fade, useMediaQuery } from "@mui/material";
import Navbar from "../widgets/navbar/navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import Footer from "../widgets/footer";
import { useTheme } from "@mui/material/styles";

const MainBanner = lazy(() =>
  import("../../default/widgets/sliders/main-banner")
);

const ImageBackroundEffect = lazy(() =>
  import("../../default/widgets/sliders/image-backround-effect")
);

const CategoriesSlider = lazy(() =>
  import("../../default/widgets/sliders/categories-slider")
);

const CollectionSlider = lazy(() =>
  import("../../default/widgets/sliders/collection-slider")
);

const Grid2Section = lazy(() =>
  import("../../default/widgets/sliders/k-world-grid-2")
);

const FeaturedSlider = lazy(() =>
  import("../../default/widgets/sliders/featured-slider")
);

const ExportDefault = (props) => {
  const [visibleSections, setVisibleSections] = useState({
    categoriesSlider1: false,
    categoriesSlider2: false,
    collectionSlider: false,
    featuredSlider: false,
    grid2Section: false,
  });

  const sectionRefs = useRef({
    categoriesSlider1: null,
    categoriesSlider2: null,
    collectionSlider: null,
    featuredSlider: null,
    grid2Section: null,
  });

  const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px 0px 500px 0px", // Start loading 500px before entering the viewport
      threshold: 0.01,
    };

    const observerCallback = (entries, observer) => {
      let newVisibleSections = {};
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          newVisibleSections[sectionId] = true;
          observer.unobserve(entry.target);
        }
      });

      // Batch updates
      if (Object.keys(newVisibleSections).length) {
        setVisibleSections((prev) => ({ ...prev, ...newVisibleSections }));
      }
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#000" }} size={80} thickness={1.5} />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar bg_color="#fff" {...props} />
        <MenuDrawer {...props} />
        <SubMenuDrawer {...props} />
        <MainBanner
          {...{
            ...props,
            ...{
              slide: {
                imagesPath: "/marketing/main",
                slug: "main-banner",
                title: "CLASSIC MIXED METALS",
                desc: "Discover our signature style: sterling silver masterpieces enhanced by radiant touches of 18k gold that echo our legacy in every detail.",
                descMaxWidth: "640px",
                url: "/women/metal/mixed-metals",

                buttons: [
                  {
                    name: "SHOP WOMEN’S",
                    url: "/women/metal/mixed-metals",
                  },
                  { name: "SHOP MEN’S", url: "/men/metal/mixed-metals" },
                ],
              },
            },
          }}
        />
        <Fade in={visibleSections.categoriesSlider1} timeout={0}>
          <div
            id="categoriesSlider1"
            ref={(el) => (sectionRefs.current.categoriesSlider1 = el)}
          >
            {isDesktop ? (
              <CategoriesSlider
                {...{
                  ...props,
                  ...{
                    menu: {
                      slug: "category",
                      name: "BY CATEGORY",
                      direction: "row-reverse",
                      subMenu: {
                        "SHOP WOMEN’S": [
                          {
                            slug: "women-rings",
                            title: "Rings",
                            url: "/women/type/ring",
                          },
                          {
                            slug: "women-bracelets",
                            title: "Bracelets",
                            url: "/women/type/bracelet",
                          },
                          {
                            slug: "women-pendants",
                            title: "Pendants & Charms",
                            url: "/women/type/pendant",
                          },
                          {
                            slug: "women-earrings",
                            title: "Earrings",
                            url: "/women/type/earrings",
                          },
                          {
                            slug: "women-necklaces",
                            title: "Necklaces",
                            url: "/women/type/necklace",
                          },
                          {
                            slug: "women-all_",
                            title: "All",
                            url: "/women/types/all",
                          },
                        ],
                        "SHOP MEN’S": [
                          {
                            slug: "men-rings",
                            title: "Rings",
                            url: "/men/type/ring",
                          },
                          {
                            slug: "men-bracelets",
                            title: "Bracelets",
                            url: "/men/type/bracelet",
                          },
                          {
                            slug: "men-pendants",
                            title: "Pendants & Talismans",
                            url: "/men/type/pendant",
                          },
                          {
                            slug: "men-necklaces",
                            title: "Necklaces & Chains",
                            url: "/men/type/necklace",
                          },
                          {
                            slug: "men-cufflinks",
                            title: "Cufflinks",
                            url: "/men/type/cufflinks",
                          },
                          {
                            slug: "men-all_",
                            title: "All",
                            url: "/men/types/all",
                          },
                        ],
                      },
                    },
                  },
                }}
              />
            ) : (
              <CollectionSlider
                {...{
                  ...props,
                  ...{
                    title: "BY CATEGORY",
                    imagesPath: "/marketing/menu/portrait",
                    slides: [
                      {
                        slug: "women-rings",
                        title: "Rings",
                        desc: "Explore our selection of rings.",
                        url: "/women/type/ring",
                        buttons: [
                          {
                            slug: "women-rings",
                            name: "WOMEN'S",
                            url: "/women/type/ring",
                          },
                          {
                            slug: "men-rings",
                            name: "MEN'S",
                            url: "/men/type/ring",
                          },
                        ],
                      },
                      {
                        slug: "women-bracelets",
                        title: "Bracelets",
                        desc: "Explore our selection of bracelets.",
                        url: "/women/type/bracelet",
                        buttons: [
                          {
                            slug: "women-bracelets",
                            name: "WOMEN'S",
                            url: "/women/type/bracelet",
                          },
                          {
                            slug: "men-bracelets",
                            name: "MEN'S",
                            url: "/men/type/bracelet",
                          },
                        ],
                      },
                      {
                        slug: "women-pendants",
                        title: "Pendants",
                        desc: "Explore our selection of pendants.",
                        url: "/women/type/pendant",
                        buttons: [
                          {
                            slug: "women-pendants",
                            name: "WOMEN'S",
                            url: "/women/type/pendant",
                          },
                          {
                            slug: "men-pendants",
                            name: "MEN'S",
                            url: "/men/type/pendant",
                          },
                        ],
                      },
                      {
                        slug: "women-earrings",
                        title: "Earrings",
                        desc: "Explore our selection of earrings.",
                        url: "/women/type/earrings",
                        buttons: [
                          {
                            slug: "women-earrings",
                            name: "WOMEN'S",
                            url: "/women/type/earrings",
                          },
                        ],
                      },
                      {
                        slug: "women-necklaces",
                        title: "Necklaces",
                        desc: "Explore our selection of necklaces.",
                        url: "/women/type/necklace",
                        buttons: [
                          {
                            slug: "women-necklaces",
                            name: "WOMEN'S",
                            url: "/women/type/necklace",
                          },
                          {
                            slug: "men-necklaces",
                            name: "MEN'S",
                            url: "/men/type/necklace",
                          },
                        ],
                      },
                      {
                        slug: "men-cufflinks",
                        title: "Cufflinks",
                        desc: "Explore our selection of cufflinks.",
                        url: "/men/type/cufflinks",
                        buttons: [
                          {
                            slug: "men-cufflinks",
                            name: "MEN'S",
                            url: "/men/type/cufflinks",
                          },
                        ],
                      },
                    ],
                  },
                }}
              />
            )}
          </div>
        </Fade>
        <ImageBackroundEffect
          {...{
            ...props,
            ...{
              slide: {
                slug: "home-single-image",
                url: "/",
                imagesPath: "/marketing/background",
              },
            },
          }}
        />
        <Fade in={visibleSections.categoriesSlider2} timeout={0}>
          <div
            id="categoriesSlider2"
            ref={(el) => (sectionRefs.current.categoriesSlider2 = el)}
          >
            {isDesktop ? (
              <CategoriesSlider
                {...{
                  ...props,
                  ...{
                    menu: {
                      slug: "category",
                      name: "BY MATERIAL",
                      direction: "row",
                      subMenu: {
                        "SHOP WOMEN’S": [
                          {
                            slug: "women-gold",
                            title: "Gold",
                            url: "/women/metal/18kt-gold",
                          },
                          {
                            slug: "women-mixed-metals",
                            title: "Mixed Metals",
                            url: "/women/metal/mixed-metals",
                          },
                          {
                            slug: "women-sterling-silver",
                            title: "Sterling Silver",
                            url: "/women/metal/sterling-silver",
                          },
                          {
                            slug: "women-leather",
                            title: "Leather",
                            url: "/women/metal/leather",
                          },
                        ],
                        "SHOP MEN’S": [
                          {
                            slug: "men-gold",
                            title: "Gold",
                            url: "/men/metal/18kt-gold",
                          },
                          {
                            slug: "men-mixed-metals",
                            title: "Mixed Metals",
                            url: "/men/metal/mixed-metals",
                          },
                          {
                            slug: "men-sterling-silver",
                            title: "Sterling Silver",
                            url: "/men/metal/sterling-silver",
                          },
                          {
                            slug: "men-leather",
                            title: "Leather",
                            url: "/men/metal/leather",
                          },
                        ],
                      },
                    },
                  },
                }}
              />
            ) : (
              <CollectionSlider
                {...{
                  ...props,
                  ...{
                    title: "BY MATERIAL",
                    imagesPath: "/marketing/menu/portrait",
                    slides: [
                      {
                        slug: "women-gold",
                        title: "Gold",
                        desc: "Explore our luxurious gold collections.",
                        url: "/women/metal/18kt-gold",
                        buttons: [
                          {
                            slug: "women-gold",
                            name: "WOMEN'S",
                            url: "/women/metal/18kt-gold",
                          },
                          {
                            slug: "men-gold",
                            name: "MEN'S",
                            url: "/men/metal/18kt-gold",
                          },
                        ],
                      },
                      {
                        slug: "men-mixed-metals",
                        title: "Mixed Metals",
                        desc: "Explore our luxurious mixed metals collections.",
                        url: "/men/metal/mixed-metals",
                        buttons: [
                          {
                            slug: "women-mixed-metals",
                            name: "WOMEN'S",
                            url: "/women/metal/mixed-metals",
                          },
                          {
                            slug: "men-mixed-metals",
                            name: "MEN'S",
                            url: "/men/metal/mixed-metals",
                          },
                        ],
                      },
                      {
                        slug: "women-sterling-silver",
                        title: "Sterling Silver",
                        desc: "Explore our luxurious sterling silver collections.",
                        url: "/women/metal/sterling-silver",
                        buttons: [
                          {
                            slug: "women-sterling-silver",
                            name: "WOMEN'S",
                            url: "/women/metal/sterling-silver",
                          },
                          {
                            slug: "men-sterling-silver",
                            name: "MEN'S",
                            url: "/men/metal/sterling-silver",
                          },
                        ],
                      },
                      {
                        slug: "men-leather",
                        title: "Leather",
                        desc: "Explore our luxurious leather collections.",
                        url: "/men/metal/leather",
                        buttons: [
                          {
                            slug: "women-leather",
                            name: "WOMEN'S",
                            url: "/women/metal/leather",
                          },
                          {
                            slug: "men-leather",
                            name: "MEN'S",
                            url: "/men/metal/leather",
                          },
                        ],
                      },
                    ],
                  },
                }}
              />
            )}
          </div>
        </Fade>
        <Fade in={visibleSections.collectionSlider} timeout={0}>
          <div
            id="collectionSlider"
            ref={(el) => (sectionRefs.current.collectionSlider = el)}
          >
            <CollectionSlider
              {...{
                ...props,
                ...{
                  title: "NEW COLLECTIONS",
                  imagesPath: "/marketing/collections",
                  slides: [
                    {
                      slug: "kavos",
                      title: "KAVOS COLLECTION",
                      desc: "Discover nautical designs that echo Greek fishing ships and artful rope motifs.",
                      url: "/men/collection/kavos",
                      buttons: [
                        {
                          name: "SHOP MOON",
                          url: "/men/collection/kavos",
                        },
                      ],
                    },
                    {
                      slug: "karia",
                      title: "KARIA COLLECTION",
                      desc: "Discover Karia’s designs with chiseled details, flowing forms, and majestic stones.",
                      url: "/women/collection/karia",
                      buttons: [
                        {
                          name: "SHOP KARIA",
                          url: "/women/collection/karia",
                        },
                      ],
                    },
                    {
                      slug: "kyma",
                      title: "KYMA COLLECTION",
                      desc: "Discover designs meticulously crafted to capture the Aegean Sea’s waves with clean lines, smooth curves, and sculptural forms.",
                      url: "/women/collection/kyma",
                      buttons: [
                        {
                          name: "SHOP KYMA",
                          url: "/women/collection/kyma",
                        },
                      ],
                    },
                    {
                      slug: "gen-k",
                      title: "GEN-K COLLECTION",
                      desc: "Discover our revitalized Gen-K collection, featuring new vibrant colors and reimagined designs.",
                      url: "/women/collection/gen-k",
                      buttons: [
                        {
                          name: "SHOP GEN-K",
                          url: "/women/collection/gen-k",
                        },
                      ],
                    },
                    {
                      slug: "zodiac",
                      title: "ZODIAC COLLECTION",
                      desc: "Choose from 12 enchanting zodiac characters, each intricately designed to reveal the unique essence of every sign.",
                      url: "/women/collection/zodiac",
                      buttons: [
                        {
                          name: "SHOP ZODIAC",
                          url: "/women/collection/zodiac",
                        },
                      ],
                    },
                    {
                      slug: "moon",
                      title: "MOON COLLECTION",
                      desc: "Captivating and bold, each design features two distinct stones that symbolize the full moon's transformation in the midnight sky.",
                      url: "/men/collection/moon",
                      buttons: [
                        {
                          name: "SHOP MOON",
                          url: "/men/collection/moon",
                        },
                      ],
                    },
                  ],
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.featuredSlider} timeout={0}>
          <div
            id="featuredSlider"
            ref={(el) => (sectionRefs.current.featuredSlider = el)}
          >
            <FeaturedSlider
              {...{
                ...props,
                ...{
                  title: "FEATURED",
                  imagesPath: "/marketing/featured",
                  slides: [
                    {
                      slug: "women-slider-new-in",
                      title: "NEW IN",
                      desc: "Shop new and refreshing designs from our latest collections.",
                      url: "/women/curation/new-arrivals",
                      buttons: [
                        {
                          name: "WOMEN'S",
                          url: "/women/curation/new-arrivals",
                        },
                        {
                          name: "MEN'S",
                          url: "/men/curation/new-arrivals",
                        },
                      ],
                    },
                    {
                      slug: "women-slider-best-sellers",
                      title: "BEST SELLERS",
                      desc: "Explore our top-selling designs that feature timeless qualities.",
                      url: "/women/curation/best-sellers",
                      buttons: [
                        {
                          name: "WOMEN'S",
                          url: "/women/curation/best-sellers",
                        },
                        {
                          name: "MEN'S",
                          url: "/men/curation/best-sellers",
                        },
                      ],
                    },
                    {
                      slug: "women-slider-seasonal-edit",
                      title: "SEASONAL EDIT",
                      desc: "Shop new and refreshing designs from our latest collections.",
                      url: "/women/curation/seasonal-edit",
                      buttons: [
                        {
                          name: "WOMEN'S",
                          url: "/women/curation/seasonal-edit",
                        },
                        {
                          name: "MEN'S",
                          url: "/men/curation/seasonal-edit",
                        },
                      ],
                    },
                    {
                      slug: "women-slider-novelties",
                      title: "NOVELTIES",
                      desc: "View exquisite statement novelty designs selected from each collection.",
                      url: "/women/curation/novelties",
                      buttons: [
                        {
                          name: "WOMEN'S",
                          url: "/women/curation/novelties",
                        },
                        {
                          name: "MEN'S",
                          url: "/men/curation/novelties",
                        },
                      ],
                    },
                  ],
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.grid2Section} timeout={0}>
          <div
            id="grid2Section"
            ref={(el) => (sectionRefs.current.grid2Section = el)}
          >
            <Grid2Section {...props} />
          </div>
        </Fade>
        <Footer {...props} />
      </Box>
    </Suspense>
  );
};

export default ExportDefault;
