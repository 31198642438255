import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Fade, useMediaQuery } from "@mui/material";
import Navbar from "../widgets/navbar/navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import Footer from "../widgets/footer";
import { useTheme } from "@mui/material/styles";

const MainBanner = lazy(() =>
  import("../../default/widgets/sliders/main-banner")
);

const ProductsListSlider = lazy(() =>
  import("../../../Components/Widgets/Sliders/ProductsListSlider")
);

const ImageBackroundEffect = lazy(() =>
  import("../../default/widgets/sliders/image-backround-effect")
);

const CategoriesSlider = lazy(() =>
  import("../../default/widgets/sliders/categories-slider")
);

const CollectionSlider = lazy(() =>
  import("../../default/widgets/sliders/collection-slider")
);

const FeaturedSlider = lazy(() =>
  import("../../default/widgets/sliders/featured-slider")
);

const ExportDefault = (props) => {
  const [visibleSections, setVisibleSections] = useState({
    categoriesSlider1: false,
    productsListSlider1: false,
    productsListSlider2: false,
    categoriesSlider2: false,
    collectionSlider: false,
    featuredSlider: false,
  });

  const sectionRefs = useRef({
    categoriesSlider1: null,
    productsListSlider1: null,
    productsListSlider2: null,
    categoriesSlider2: null,
    collectionSlider: null,
    featuredSlider: null,
  });

  const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));

  useEffect(() => {
    const observerOptions = {
      root: null, // Use the viewport as the container
      rootMargin: "0px 0px 500px 0px", // Start loading 500px before entering the viewport
      threshold: 0.01, // Trigger when 1% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          setVisibleSections((prev) => ({ ...prev, [sectionId]: true }));
          observer.unobserve(entry.target); // Stop observing after visibility is handled
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // Attach the observer to each section
    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect(); // Cleanup observer when component unmounts
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#000" }} size={80} thickness={1.5} />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar bg_color="#fff" {...props} />
        <MenuDrawer {...props} />
        <SubMenuDrawer {...props} />
        <MainBanner
          {...{
            ...props,
            ...{
              padding: 2,
              slide: {
                imagesPath: "/marketing/main",
                slug: "men-main-banner",
                title: "STACKING OUR SIGNATURE CREATIONS",
                desc: "Discover the art of layering with our signature bracelet and necklace designs.",
                // descMaxWidth: "640px",
                url: "/men/curation/stacking",

                buttons: [
                  {
                    name: "EXPLORE ALL",
                    url: "/men/curation/stacking",
                  },
                ],
              },
            },
          }}
        />
        <Fade in={visibleSections.productsListSlider1} timeout={0}>
          <div
            id="productsListSlider1"
            ref={(el) => (sectionRefs.current.productsListSlider1 = el)}
          >
            <ProductsListSlider
              {...{
                ...props,
                ...{
                  params: {
                    pageAmount: 100,
                    curation: "stacking",
                    gender: "men",
                  },
                  amount: 15,
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.categoriesSlider1} timeout={0}>
          <div
            id="categoriesSlider1"
            ref={(el) => (sectionRefs.current.categoriesSlider1 = el)}
          >
            {isDesktop ? (
              <CategoriesSlider
                {...{
                  ...props,
                  ...{
                    menu: {
                      slug: "category",
                      name: "BY CATEGORY",
                      direction: "row-reverse",
                      subMenu: {
                        "SHOP MEN’S": [
                          {
                            slug: "men-rings",
                            title: "Rings",
                            url: "/men/type/ring",
                          },
                          {
                            slug: "men-bracelets",
                            title: "Bracelets",
                            url: "/men/type/bracelet",
                          },
                          {
                            slug: "men-pendants",
                            title: "Pendants & Talismans",
                            url: "/men/type/pendant",
                          },
                          {
                            slug: "men-necklaces",
                            title: "Necklaces & Chains",
                            url: "/men/type/necklace",
                          },
                          {
                            slug: "men-cufflinks",
                            title: "Cufflinks",
                            url: "/men/type/cufflinks",
                          },
                          {
                            slug: "men-all_",
                            title: "All",
                            url: "/men/types/all",
                          },
                        ],
                      },
                    },
                  },
                }}
              />
            ) : (
              <CollectionSlider
                {...{
                  ...props,
                  ...{
                    title: "BY CATEGORY",
                    imagesPath: "/marketing/menu/portrait",
                    slides: [
                      {
                        slug: "men-rings",
                        title: "Rings",
                        desc: "Explore our selection of rings.",
                        url: "/men/type/ring",
                        buttons: [
                          {
                            slug: "men-rings",
                            name: "MEN'S",
                            url: "/men/type/ring",
                          },
                        ],
                      },
                      {
                        slug: "men-bracelets",
                        title: "Bracelets",
                        desc: "Explore our selection of bracelets.",
                        url: "/men/type/bracelet",
                        buttons: [
                          {
                            slug: "men-bracelets",
                            name: "MEN'S",
                            url: "/men/type/bracelet",
                          },
                        ],
                      },
                      {
                        slug: "men-pendants",
                        title: "Pendants",
                        desc: "Explore our selection of pendants.",
                        url: "/men/type/pendant",
                        buttons: [
                          {
                            slug: "men-pendants",
                            name: "MEN'S",
                            url: "/men/type/pendant",
                          },
                        ],
                      },
                      {
                        slug: "men-necklaces",
                        title: "Necklaces",
                        desc: "Explore our selection of necklaces.",
                        url: "/men/type/necklace",
                        buttons: [
                          {
                            slug: "men-necklaces",
                            name: "MEN'S",
                            url: "/men/type/necklace",
                          },
                        ],
                      },
                      {
                        slug: "men-cufflinks",
                        title: "Cufflinks",
                        desc: "Explore our selection of cufflinks.",
                        url: "/men/type/cufflinks",
                        buttons: [
                          {
                            slug: "men-cufflinks",
                            name: "MEN'S",
                            url: "/men/type/cufflinks",
                          },
                        ],
                      },
                    ],
                  },
                }}
              />
            )}
          </div>
        </Fade>
        <ImageBackroundEffect
          {...{
            ...props,
            ...{
              slide: {
                slug: "men-single-image",
                url: "/men",
                imagesPath: "/marketing/background",
              },
            },
          }}
        />
        <Fade in={visibleSections.categoriesSlider2} timeout={0}>
          <div
            id="categoriesSlider2"
            ref={(el) => (sectionRefs.current.categoriesSlider2 = el)}
          >
            {isDesktop ? (
              <CategoriesSlider
                {...{
                  ...props,
                  ...{
                    menu: {
                      slug: "category",
                      name: "BY MATERIAL",
                      direction: "row",
                      subMenu: {
                        "SHOP MEN’S": [
                          {
                            slug: "men-gold",
                            title: "Gold",
                            url: "/men/metal/18kt-gold",
                          },
                          {
                            slug: "men-mixed-metals",
                            title: "Mixed Metals",
                            url: "/men/metal/mixed-metals",
                          },
                          {
                            slug: "men-sterling-silver",
                            title: "Sterling Silver",
                            url: "/men/metal/sterling-silver",
                          },
                          {
                            slug: "men-leather",
                            title: "Leather",
                            url: "/men/metal/leather",
                          },
                        ],
                      },
                    },
                  },
                }}
              />
            ) : (
              <CollectionSlider
                {...{
                  ...props,
                  ...{
                    title: "BY MATERIAL",
                    imagesPath: "/marketing/menu/portrait",
                    slides: [
                      {
                        slug: "men-gold",
                        title: "Gold",
                        desc: "Explore our luxurious gold collections.",
                        url: "/men/metal/18kt-gold",
                        buttons: [
                          {
                            slug: "men-gold",
                            name: "MEN'S",
                            url: "/men/metal/18kt-gold",
                          },
                        ],
                      },
                      {
                        slug: "men-mixed-metals",
                        title: "Mixed Metals",
                        desc: "Explore our luxurious mixed metals collections.",
                        url: "/men/metal/mixed-metals",
                        buttons: [
                          {
                            slug: "men-mixed-metals",
                            name: "MEN'S",
                            url: "/men/metal/mixed-metals",
                          },
                        ],
                      },
                      {
                        slug: "men-sterling-silver",
                        title: "Sterling Silver",
                        desc: "Explore our luxurious sterling silver collections.",
                        url: "/men/metal/sterling-silver",
                        buttons: [
                          {
                            slug: "men-sterling-silver",
                            name: "MEN'S",
                            url: "/men/metal/sterling-silver",
                          },
                        ],
                      },
                      {
                        slug: "men-leather",
                        title: "Leather",
                        desc: "Explore our luxurious leather collections.",
                        url: "/men/metal/leather",
                        buttons: [
                          {
                            slug: "men-leather",
                            name: "MEN'S",
                            url: "/men/metal/leather",
                          },
                        ],
                      },
                    ],
                  },
                }}
              />
            )}
          </div>
        </Fade>
        <Fade in={visibleSections.featuredSlider} timeout={0}>
          <div
            id="featuredSlider"
            ref={(el) => (sectionRefs.current.featuredSlider = el)}
          >
            <FeaturedSlider
              {...{
                ...props,
                ...{
                  title: "FEATURED CURATIONS",
                  imagesPath: "/marketing/featured",
                  // infoPosition: "absolute",
                  slides: [
                    {
                      slug: "men-slider-new-in",
                      title: "NEW IN",
                      desc: "Shop new and refreshing designs from our latest collections.",
                      url: "/men/curation/new-arrivals",
                      buttons: [
                        {
                          name: "MEN'S",
                          url: "/men/curation/new-arrivals",
                        },
                      ],
                    },
                    {
                      slug: "men-slider-best-sellers",
                      title: "BEST SELLERS",
                      desc: "Explore our top-selling designs that feature timeless qualities.",
                      url: "/men/curation/best-sellers",
                      buttons: [
                        {
                          name: "MEN'S",
                          url: "/men/curation/best-sellers",
                        },
                      ],
                    },
                    {
                      slug: "men-slider-seasonal-edit",
                      title: "SEASONAL EDIT",
                      desc: "Shop new and refreshing designs from our latest collections.",
                      url: "/men/curation/seasonal-edit",
                      buttons: [
                        {
                          name: "MEN'S",
                          url: "/men/curation/seasonal-edit",
                        },
                      ],
                    },
                    {
                      slug: "men-slider-novelties",
                      title: "NOVELTIES",
                      desc: "View exquisite statement novelty designs selected from each collection.",
                      url: "/men/curation/novelties",
                      buttons: [
                        {
                          name: "MEN'S",
                          url: "/men/curation/novelties",
                        },
                      ],
                    },
                  ],
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.productsListSlider2} timeout={0}>
          <div
            id="productsListSlider2"
            ref={(el) => (sectionRefs.current.productsListSlider2 = el)}
          >
            <ProductsListSlider
              {...{
                ...props,
                ...{
                  title: "MEN'S GOLD",
                  url: "/men/metal/18kt-gold",
                  params: {
                    pageAmount: 100,
                    metal: "18kt-gold",
                    gender: "men",
                    type: "ring,bracelet,pendant,necklace",
                  },
                  amount: 15,
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.collectionSlider} timeout={0}>
          <div
            id="collectionSlider"
            ref={(el) => (sectionRefs.current.collectionSlider = el)}
          >
            <CollectionSlider
              {...{
                ...props,
                ...{
                  title: "EXPLORE MEN’S COLLECTIONS",
                  url: "/men/collections",
                  imagesPath: "/marketing/collections",
                  slides: [
                    {
                      slug: "kavos",
                      title: "KAVOS COLLECTION",
                      desc: "Discover nautical designs that echo Greek fishing ships and artful rope motifs.",
                      url: "/men/collection/kavos",
                      buttons: [
                        {
                          name: "SHOP MOON",
                          url: "/men/collection/kavos",
                        },
                      ],
                    },
                    {
                      slug: "moon",
                      title: "MOON COLLECTION",
                      desc: "Captivating and bold, each design features two distinct stones that symbolize the full moon's transformation in the midnight sky.",
                      url: "/men/collection/moon",
                      buttons: [
                        {
                          name: "SHOP MOON",
                          url: "/men/collection/moon",
                        },
                      ],
                    },
                    {
                      slug: "zodiac",
                      title: "ZODIAC COLLECTION",
                      desc: "Choose from 12 enchanting zodiac characters, each intricately designed to reveal the unique essence of every sign.",
                      url: "/women/collection/zodiac",
                      buttons: [
                        {
                          name: "SHOP ZODIAC",
                          url: "/women/collection/zodiac",
                        },
                      ],
                    },
                    {
                      slug: "alchemy",
                      title: "ALCHEMY COLLECTION",
                      desc: "Experience the captivating allure of intricately designed serpentine patterns that exude a unique and irresistible charm.",
                      url: "/men/collection/alchemy",
                      buttons: [
                        {
                          name: "SHOP ALCHEMY",
                          url: "/men/collection/alchemy",
                        },
                      ],
                    },
                    {
                      slug: "laconia",
                      title: "LACONIA COLLECTION",
                      desc: "Explore a fresh wave of men’s designs inspired by ancient Spartan armor through emblematic engravings and motifs.",
                      url: "/men/collection/laconia",
                      buttons: [
                        {
                          name: "SHOP LACONIA",
                          url: "/men/collection/laconia",
                        },
                      ],
                    },
                  ],
                },
              }}
            />
          </div>
        </Fade>
        <Footer {...props} />
      </Box>
    </Suspense>
  );
};

export default ExportDefault;
