import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Fade } from "@mui/material";
import Navbar from "../widgets/navbar/navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import Footer from "../widgets/footer";

const MainBanner = lazy(() =>
  import("../../default/widgets/sliders/main-banner")
);

const CollectionSlider = lazy(() =>
  import("../../default/widgets/sliders/collection-slider")
);

const AllCollections = lazy(() =>
  import("../../default/widgets/sliders/all-collections")
);

const ExportDefault = (props) => {
  const [visibleSections, setVisibleSections] = useState({
    collectionSlider: false,
    allCollections: false,
  });

  const sectionRefs = useRef({
    collectionSlider: null,
    allCollections: null,
  });

  useEffect(() => {
    const observerOptions = {
      root: null, // Use the viewport as the container
      rootMargin: "0px 0px 500px 0px", // Start loading 500px before entering the viewport
      threshold: 0.01, // Trigger when 1% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          setVisibleSections((prev) => ({ ...prev, [sectionId]: true }));
          observer.unobserve(entry.target); // Stop observing after visibility is handled
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // Attach the observer to each section
    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect(); // Cleanup observer when component unmounts
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#000" }} size={80} thickness={1.5} />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar bg_color="#fff" {...props} />
        <MenuDrawer {...props} />
        <SubMenuDrawer {...props} />
        <MainBanner
          {...{
            ...props,
            ...{
              slide: {
                imagesPath: "/marketing/main",
                slug: "men-collections-main-banner",
                title: "Men’s Gold Creations.",
                desc: "Discover luxurious 18k gold designs inspired by Archaic and Hellenistic warrior armor, adorned with precious stones.",
                // descMaxWidth: "640px",
                url: "/men/metal/18kt-gold",
                buttons: [
                  {
                    name: "EXPLORE ALL",
                    url: "/men/metal/18kt-gold",
                  },
                ],
              },
            },
          }}
        />
        <Fade in={visibleSections.collectionSlider} timeout={0}>
          <div
            id="collectionSlider"
            ref={(el) => (sectionRefs.current.collectionSlider = el)}
          >
            <CollectionSlider
              {...{
                ...props,
                ...{
                  title: "MEN’S RECENT COLLECTIONS",
                  imagesPath: "/marketing/collections",
                  slides: [
                    {
                      slug: "kavos",
                      title: "KAVOS COLLECTION",
                      desc: "Discover nautical designs that echo Greek fishing ships and artful rope motifs.",
                      url: "/men/collection/kavos",
                      buttons: [
                        {
                          name: "SHOP MOON",
                          url: "/men/collection/kavos",
                        },
                      ],
                    },
                    {
                      slug: "moon",
                      title: "MOON COLLECTION",
                      desc: "Captivating and bold, each design features two distinct stones that symbolize the full moon's transformation in the midnight sky.",
                      url: "/men/collection/moon",
                      buttons: [
                        {
                          name: "SHOP MOON",
                          url: "/men/collection/moon",
                        },
                      ],
                    },
                    {
                      slug: "zodiac",
                      title: "ZODIAC COLLECTION",
                      desc: "Choose from 12 enchanting zodiac characters, each intricately designed to reveal the unique essence of every sign.",
                      url: "/women/collection/zodiac",
                      buttons: [
                        {
                          name: "SHOP ZODIAC",
                          url: "/women/collection/zodiac",
                        },
                      ],
                    },
                    {
                      slug: "alchemy",
                      title: "ALCHEMY COLLECTION",
                      desc: "Experience the captivating allure of intricately designed serpentine patterns that exude a unique and irresistible charm.",
                      url: "/men/collection/alchemy",
                      buttons: [
                        {
                          name: "SHOP ALCHEMY",
                          url: "/men/collection/alchemy",
                        },
                      ],
                    },
                    {
                      slug: "laconia",
                      title: "LACONIA COLLECTION",
                      desc: "Explore a fresh wave of men’s designs inspired by ancient Spartan armor through emblematic engravings and motifs.",
                      url: "/men/collection/laconia",
                      buttons: [
                        {
                          name: "SHOP LACONIA",
                          url: "/men/collection/laconia",
                        },
                      ],
                    },
                  ],
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.allCollections} timeout={0}>
          <div
            id="allCollections"
            ref={(el) => (sectionRefs.current.allCollections = el)}
          >
            <AllCollections
              {...{
                ...props,
                ...{
                  gender: "men",
                },
              }}
            />
          </div>
        </Fade>
        <Footer {...props} />
      </Box>
    </Suspense>
  );
};

export default ExportDefault;
