import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Fade } from "@mui/material";
import Navbar from "../../widgets/navbar/navbar";
import MenuDrawer from "../../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../../Components/Widgets/SubMenuDrawer";
import Footer from "../../widgets/footer";

const ImageBackroundEffect = lazy(() =>
  import("../../../default/widgets/sliders/image-backround-effect")
);

const About = lazy(() => import("../../../default/widgets/about/about"));

const ExportDefault = (props) => {
  const [visibleSections, setVisibleSections] = useState({
    about: false,
  });

  const sectionRefs = useRef({
    about: null,
  });

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px 0px 500px 0px", // Start loading 500px before entering the viewport
      threshold: 0.01,
    };

    const observerCallback = (entries, observer) => {
      let newVisibleSections = {};
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          newVisibleSections[sectionId] = true;
          observer.unobserve(entry.target);
        }
      });

      // Batch updates
      if (Object.keys(newVisibleSections).length) {
        setVisibleSections((prev) => ({ ...prev, ...newVisibleSections }));
      }
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#000" }} size={80} thickness={1.5} />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar bg_color="#fff" {...props} />
        <MenuDrawer {...props} />
        <SubMenuDrawer {...props} />
        <ImageBackroundEffect
          {...{
            ...props,
            ...{
              slide: {
                slug: "about-banner",
                url: "/about",
                imagesPath: "/marketing/main",
              },
            },
          }}
        />
        <Fade in={visibleSections.about} timeout={0}>
          <div id="about" ref={(el) => (sectionRefs.current.about = el)}>
            <About {...props} />
          </div>
        </Fade>
        <Footer {...props} />
      </Box>
    </Suspense>
  );
};

export default ExportDefault;
