import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Box,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Link,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Alert,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import ProductCheckoutView from "../../../../Components/Widgets/ProductCheckoutView";
import ForgotPasswordDialog from "../../../../Components/Widgets/Dialogs/ForgotPasswordDialog";
import AddressDialog from "../../../../Components/Widgets/Dialogs/AddressDialog";
import AddressForm from "../dialogs/address_form";
import CouponsSection from "./coupons_section";
import Translate from "../../../../../modules/Core/Components/Translate";

const LoginRegisterBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: "12px 14px",
  background: "#000",
  borderRadius: 0,
  border: "1px solid #000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
  "&:disabled": {
    opacity: 0.8,
    color: "#fff",
  },
}));

const ChangeUserTypeBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: "12px 14px",
  background: "transparent",
  borderRadius: 0,
  border: "1px solid #000",
  color: "#000",
  "&:hover": {
    background: "transparent",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

const MyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "25px",
  flexGrow: 1,
}));

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: "8px 0px",
  borderBottom: "none",
  fontSize: 16,
  "@media (max-width: 580px )": {
    padding: "8px 10px 8px 0px",
  },
}));

const MyAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "unset",
  boxShadow: "unset",
  "&::before": { opacity: "0!important" },
  "& .MuiAccordionSummary-root.Mui-disabled": {
    opacity: 1,
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "12px 0px",
  },
}));

const SigninParentGrid = styled(Grid)(({ theme }) => ({
  width: "60%",
  margin: "0 20%",
  "@media (max-width: 480px )": {
    width: "100%",
    margin: 0,
  },
}));

const RadioBtn = styled(Radio)(({ theme }) => ({
  color: "#000",
  "&.Mui-checked": {
    color: "#000",
    "&:hover": {
      background: "transparent",
    },
  },
  "&:hover": {
    background: "transparent",
  },
}));

const RadioLabel = styled("div")(({ theme }) => ({
  border: "1px solid #000",
  fontSize: 18,
  padding: 10,
}));

const MyFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 10,
  "& .MuiFormControlLabel-label": {
    width: "100%",
    flexGrow: 1,
  },
}));

const ActionBtn = styled(Link)(({ theme }) => ({
  color: "#000",
  fontSize: "13px",
  fontWeight: "400",
  cursor: "pointer",
  borderBottom: "1px solid #000",
  "&:hover": {
    borderBottom: "1px solid transparent",
  },
}));

const StepEditBtn = styled(Link)(({ theme }) => ({
  float: "right",
  color: "#000",
  fontSize: "15px",
  fontWeight: "400",
  cursor: "pointer",
  borderBottom: "1px solid #000",
  pointerEvents: "auto",
  height: 25,
  "&:hover": {
    borderBottom: "1px solid transparent",
  },
}));

const AddAddressBtn = styled(Button)(({ theme }) => ({
  background: "transparent",
  border: "1px solid #000",
  borderRadius: 0,
  color: "#000",
  height: 48,
  marginBottom: 20,
  textTransform: "none",
  "&:hover": {
    border: "1px solid #000",
    background: "#fff",
  },
}));

export default function Checkout(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const {
    order = null,
    username,
    expanded = false,
    guest,
    // paymentTypes = [],
    addresses = null,
  } = component.getData("default", {});

  const [newAddressDialogOpen, setNewAddressDialogOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(true);

  const onAddressSubmit = component.getData("onSubmit", true);

  //change user type
  const changeUserType = () => {
    component.setData({
      "default.guest": !guest,
      "error.fields.auth": "",
      "error.message.auth": "",
    });
  };

  //same billing adrress or not
  const handleCheck = () => {
    setChecked((prev) => !prev);
    if (checked) {
      setTimeout(() => {
        var element = document.getElementById("billing_address_title");
        window.scrollTo({
          top: element.getBoundingClientRect().top + window.pageYOffset - 75,
          behavior: "smooth",
        });
      }, 100);
      component.initGoogleAutocomplete("billing_address");
    }
  };

  //show/hide password
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //forgot password dialog
  const [open, setOpen] = React.useState(false);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 6,
        mb: 4,
        px: { xs: 2, md: 3 },
        minHeight: "600px",
        "& .MuiAccordion-root.Mui-disabled": {
          backgroundColor: "transparent",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          paddingBottom: "20px",
        },
      }}
      maxWidth="xl"
      disableGutters
    >
      {order && (
        <Grid
          container
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          direction="row"
          spacing={4}
        >
          <Grid item md={7} xs={12}>
            <MyTitle
              variant="h3"
              gutterBottom
              sx={{
                my: "20px 0px",
              }}
            >
              {component.ucfirst("checkout-title-1")}
            </MyTitle>
            {/* Guest or signin */}
            <MyAccordion
              expanded={expanded === "step1"}
              disabled
              id="step1"
              disableGutters
            >
              <AccordionSummary
                sx={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                  padding: "20px 0px",
                }}
              >
                <MyTitle>
                  {expanded !== "step1" && expanded !== false
                    ? component.ucfirst("welcome") +
                      " " +
                      component.ucfirst(username) +
                      "!"
                    : component.ucfirst("checkout-title-2")}
                </MyTitle>
                {expanded !== "step1" && expanded !== false && guest && (
                  <StepEditBtn
                    underline="none"
                    onClick={() =>
                      component.setData({ "default.expanded": "step1" })
                    }
                  >
                    {component.ucfirst("sign-in")}
                  </StepEditBtn>
                )}
              </AccordionSummary>
              <AccordionDetails sx={{ display: "block" }}>
                <form onSubmit={(e) => component.login(e)}>
                  <SigninParentGrid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <MyTextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label={component.ucfirst("email-textfield")}
                        name="email"
                        autoComplete="email"
                        helperText={component.getData(
                          "error.fields.auth.username"
                        )}
                        value={component.getData(
                          "default.fields.auth.username",
                          ""
                        )}
                        onChange={(e) => {
                          component.setData({
                            "default.fields.auth.username": e.target.value,
                          });
                          component.deleteData("error.fields.auth.username");
                        }}
                        error={component.dataExists(
                          "error.fields.auth.username"
                        )}
                      />
                    </Grid>
                    {!guest && (
                      <Grid item xs={12}>
                        <PasswordField
                          variant="outlined"
                          error={component.dataExists(
                            "error.fields.auth.password"
                          )}
                        >
                          <InputLabel>
                            {component.ucfirst("password-textfield")}
                          </InputLabel>
                          <OutlinedInput
                            type={values.showPassword ? "text" : "password"}
                            value={component.getData(
                              "default.fields.auth.password",
                              ""
                            )}
                            onChange={(e) => {
                              component.setData({
                                "default.fields.auth.password": e.target.value,
                              });
                              component.deleteData(
                                "error.fields.auth.password"
                              );
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  disableRipple
                                  sx={{
                                    "&:hover, &:focus": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label={component.ucfirst("password-textfield")}
                          />
                          {component.dataExists(
                            "error.fields.auth.password"
                          ) && (
                            <FormHelperText
                              error
                              style={{ margin: "3px 14px 0" }}
                            >
                              {component.getData("error.fields.auth.password")}
                            </FormHelperText>
                          )}
                        </PasswordField>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <LoginRegisterBtn type="submit" fullWidth>
                        {guest
                          ? component.ucfirst("guest-btn")
                          : component.ucfirst("sign-in-btn")}
                      </LoginRegisterBtn>
                      {!guest && (
                        <Link
                          variant="body2"
                          component="button"
                          underline="hover"
                          type="button"
                          onClick={() => setOpen(true)}
                          style={{
                            color: "#7d7d7d",
                          }}
                        >
                          {component.ucfirst("forgot-password")}
                        </Link>
                      )}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography>{component.trans("or")}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <ChangeUserTypeBtn
                        onClick={() => changeUserType()}
                        fullWidth
                      >
                        {!guest
                          ? component.ucfirst("guest-btn")
                          : component.ucfirst("sign-in-btn")}
                      </ChangeUserTypeBtn>
                    </Grid>
                  </SigninParentGrid>
                </form>
              </AccordionDetails>
            </MyAccordion>
            {/* Addresses */}
            <MyAccordion
              expanded={expanded === "step2"}
              disabled
              id="step2"
              disableGutters
            >
              <AccordionSummary
                sx={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                  padding: "20px 0px",
                }}
              >
                <MyTitle>{component.ucfirst("checkout-title-3")}</MyTitle>
                {(expanded === "step3" || expanded === "step4") && (
                  <StepEditBtn
                    underline="none"
                    onClick={() =>
                      component.setData({ "default.expanded": "step2" })
                    }
                  >
                    {component.ucfirst("edit-btn")}
                  </StepEditBtn>
                )}
              </AccordionSummary>
              <form onSubmit={(e) => component.addAddress(e)}>
                <AccordionDetails sx={{ display: "block" }}>
                  {!guest && addresses && addresses.length !== 0 ? (
                    <>
                      <RadioGroup
                        name="shipping_address"
                        id="shipping_address"
                        value={component.findAddressSelected("shipping")}
                        onChange={(event) => {
                          component.selectAddress(
                            event.target.value,
                            "shipping"
                          );
                        }}
                      >
                        {addresses.map((address) => {
                          return (
                            <MyFormControlLabel
                              key={devHelper.getObjectValue(address, "id")}
                              value={devHelper.getObjectValue(address, "id")}
                              control={<RadioBtn disableRipple />}
                              label={
                                <RadioLabel>
                                  {component.ucfirst(
                                    devHelper.getObjectValue(
                                      address,
                                      "first_name"
                                    )
                                  )}{" "}
                                  {component.ucfirst(
                                    devHelper.getObjectValue(
                                      address,
                                      "last_name"
                                    )
                                  )}
                                  <br />
                                  {devHelper.getObjectValue(address, "address")}
                                  <br />
                                  {component.ucfirst(
                                    devHelper.getObjectValue(address, "phone")
                                  )}
                                  {devHelper.getObjectValue(
                                    address,
                                    "notes"
                                  ) && <br />}
                                  {component.ucfirst(
                                    devHelper.getObjectValue(address, "notes")
                                  )}
                                  <br />
                                  <ActionBtn
                                    underline="none"
                                    onClick={() => {
                                      component.setAddressId(
                                        devHelper.getObjectValue(address, "id")
                                      );
                                      setNewAddressDialogOpen(true);
                                      component.activateAutocomplete();
                                    }}
                                  >
                                    {component.ucfirst("edit-btn")}
                                  </ActionBtn>
                                </RadioLabel>
                              }
                            />
                          );
                        })}
                      </RadioGroup>
                      <div>
                        <AddAddressBtn
                          disableRipple
                          onClick={() => {
                            setNewAddressDialogOpen(true);
                            component.activateAutocomplete();
                          }}
                        >
                          <i className="fa-light fa-plus"></i>
                          {component.trans("add-new-address-btn")}
                        </AddAddressBtn>
                      </div>
                    </>
                  ) : (
                    <AddressForm
                      {...{
                        ...props,
                        ...{
                          type: "shipping_address",
                        },
                      }}
                    />
                  )}
                  <div style={{ textAlign: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleCheck}
                          name="checkbox"
                          sx={{
                            marginTop: "-2px",
                            color: "#000",
                            "&.Mui-checked": {
                              color: "#000",
                            },
                          }}
                        />
                      }
                      label={
                        <div style={{ fontSize: "18px" }}>
                          {component.ucfirst("billing-address-checkbox")}
                        </div>
                      }
                    />
                  </div>
                  {!checked && (
                    <>
                      <MyTitle
                        style={{ margin: "10px 0 20px" }}
                        id="billing_address_title"
                      >
                        {component.ucfirst("billing-address")}
                      </MyTitle>
                      {!guest && addresses && addresses.length !== 0 ? (
                        <>
                          <RadioGroup
                            name="billing_address"
                            id="billing_address"
                            value={component.findAddressSelected("billing")}
                            onChange={(event) => {
                              component.selectAddress(
                                event.target.value,
                                "billing"
                              );
                            }}
                          >
                            {addresses.map((address) => {
                              return (
                                <MyFormControlLabel
                                  key={devHelper.getObjectValue(address, "id")}
                                  value={devHelper.getObjectValue(
                                    address,
                                    "id"
                                  )}
                                  control={<RadioBtn disableRipple />}
                                  label={
                                    <RadioLabel>
                                      {component.ucfirst(
                                        devHelper.getObjectValue(
                                          address,
                                          "first_name"
                                        )
                                      )}{" "}
                                      {component.ucfirst(
                                        devHelper.getObjectValue(
                                          address,
                                          "last_name"
                                        )
                                      )}
                                      <br />
                                      {component.ucfirst(
                                        devHelper.getObjectValue(
                                          address,
                                          "address"
                                        )
                                      )}
                                      <br />
                                      {component.ucfirst(
                                        devHelper.getObjectValue(
                                          address,
                                          "phone"
                                        )
                                      )}
                                      {devHelper.getObjectValue(
                                        address,
                                        "notes"
                                      ) && <br />}
                                      {component.ucfirst(
                                        devHelper.getObjectValue(
                                          address,
                                          "notes"
                                        )
                                      )}
                                      <br />
                                      <ActionBtn
                                        underline="none"
                                        onClick={() => {
                                          component.setAddressId(
                                            devHelper.getObjectValue(
                                              address,
                                              "id"
                                            )
                                          );
                                          setNewAddressDialogOpen(true);
                                          component.activateAutocomplete();
                                        }}
                                      >
                                        {component.ucfirst("edit-btn")}
                                      </ActionBtn>
                                    </RadioLabel>
                                  }
                                />
                              );
                            })}
                          </RadioGroup>
                          <div>
                            <AddAddressBtn
                              disableRipple
                              onClick={() => {
                                setNewAddressDialogOpen(true);
                                component.activateAutocomplete();
                              }}
                            >
                              <i className="fa-light fa-plus"></i>
                              {component.trans("add-new-address-btn")}
                            </AddAddressBtn>
                          </div>
                        </>
                      ) : (
                        <AddressForm
                          {...{
                            ...props,
                            ...{
                              type: "billing_address",
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                  <SigninParentGrid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={2}
                    style={{ marginTop: 8 }}
                  >
                    <Grid item xs={12}>
                      <LoginRegisterBtn
                        type={onAddressSubmit ? "submit" : "button"}
                        fullWidth
                        onClick={(e) => {
                          if (!onAddressSubmit) {
                            component.addAddress(e);
                          }
                        }}
                      >
                        {component.ucfirst("address-submit-btn")}
                      </LoginRegisterBtn>
                    </Grid>
                  </SigninParentGrid>
                </AccordionDetails>
              </form>
            </MyAccordion>
            {/* shipping method */}
            <MyAccordion
              expanded={expanded === "step3"}
              disabled
              id="step3"
              disableGutters
            >
              <AccordionSummary
                sx={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                  padding: "20px 0px",
                }}
              >
                <MyTitle>{component.ucfirst("checkout-title-4")}</MyTitle>
                {expanded === "step4" && (
                  <StepEditBtn
                    underline="none"
                    onClick={() =>
                      component.setData({ "default.expanded": "step3" })
                    }
                  >
                    {component.ucfirst("edit-btn")}
                  </StepEditBtn>
                )}
              </AccordionSummary>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  component.setData({ "default.expanded": "step4" });
                  component.screenScrollTo("step4");
                }}
              >
                <AccordionDetails sx={{ display: "block" }}>
                  {/* <Alert severity="info" sx={{ mb: 2 }}>
                    {component.ucfirst("shipping-message")}
                  </Alert> */}
                  <RadioGroup
                    name="shipping_method"
                    value={component.findServiceSelected()}
                  >
                    {order.services
                      .filter(
                        (service) =>
                          devHelper.getObjectValue(service, "slug") !==
                          "signature-requirement"
                      )
                      .map((service, index) => {
                        return (
                          <FormControlLabel
                            key={devHelper.getObjectValue(service, "slug")}
                            value={devHelper.getObjectValue(service, "id")}
                            control={<RadioBtn disableRipple />}
                            onClick={() =>
                              component.updateService(
                                devHelper.getObjectValue(service, "id")
                              )
                            }
                            label={
                              <Typography>
                                {devHelper.getObjectValue(service, "name")}
                              </Typography>
                            }
                          />
                        );
                      })}
                  </RadioGroup>
                  {order.services
                    .filter(
                      (service) =>
                        devHelper.getObjectValue(service, "slug") ===
                        "signature-requirement"
                    )
                    .map((service) => {
                      return (
                        <FormControlLabel
                          key={devHelper.getObjectValue(service, "slug")}
                          value={devHelper.getObjectValue(service, "id")}
                          sx={{ mt: 2 }}
                          control={
                            <Checkbox
                              sx={{
                                color: "#000",
                                "&.Mui-checked": {
                                  color: "#000",
                                },
                                // "& .MuiSvgIcon-root": {
                                //   fontSize: { xs: 20, sm: 20 },
                                // },
                              }}
                              checked={devHelper.getObjectValue(
                                service,
                                "selected"
                              )}
                              onChange={(e) => {
                                component.updateService(
                                  devHelper.getObjectValue(service, "id")
                                );
                              }}
                            />
                          }
                          label={
                            <Typography>
                              {devHelper.getObjectValue(service, "name")}
                            </Typography>
                          }
                        />
                      );
                    })}
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    {component.ucfirst("signature-requirement")}
                  </Alert>
                  <SigninParentGrid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={2}
                    style={{ marginTop: 8 }}
                  >
                    <Grid item xs={12}>
                      <LoginRegisterBtn type="submit" fullWidth>
                        {component.ucfirst("shipping-method-submit-btn")}
                      </LoginRegisterBtn>
                    </Grid>
                  </SigninParentGrid>
                </AccordionDetails>
              </form>
            </MyAccordion>
            {/* billing & payment */}
            <MyAccordion
              expanded={expanded === "step4"}
              disabled
              id="step4"
              disableGutters
            >
              <AccordionSummary
                sx={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                  padding: "20px 0px",
                }}
              >
                <MyTitle>{component.ucfirst("checkout-title-5")}</MyTitle>
              </AccordionSummary>
              <CouponsSection
                {...{
                  ...props,
                  ...{ order },
                }}
              />
              <form onSubmit={(e) => component.complete(e)}>
                <AccordionDetails sx={{ display: "block" }}>
                  {/* <RadioGroup
                    name="payment_method"
                    value={component.findPaymentTypeSelected()}
                  >
                    {paymentTypes.map((paymentType) => {
                      return (
                        <FormControlLabel
                          key={devHelper.getObjectValue(paymentType, "slug")}
                          value={devHelper.getObjectValue(paymentType, "id")}
                          control={<RadioBtn disableRipple />}
                          onClick={() =>
                            component.updatePaymentType(
                              devHelper.getObjectValue(paymentType, "id")
                            )
                          }
                          label={component.ucfirst(
                            devHelper.getObjectValue(paymentType, "slug")
                          )}
                        />
                      );
                    })}
                  </RadioGroup> */}
                  <MyTitle variant="h6" sx={{ mt: 2, mb: 1, fontSize: 20 }}>
                    Payment Methods
                  </MyTitle>
                  <div id="paypal-container" />
                  <div style={{ textAlign: "center" }}>
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            marginTop: "-2px",
                            color: "#000",
                            "&.Mui-checked": {
                              color: "#000",
                            },
                          }}
                          onChange={(e) => {
                            component.setData({
                              "default.termsAccepted": e.target.checked,
                            });
                          }}
                        />
                      }
                      label={
                        <Translate
                          entity="literal"
                          eslug="agree-privacy-policy-terms-checkbox"
                          scope="default"
                          slug="default"
                          auto
                        />
                      }
                    />
                     */}
                    <Translate
                      entity="literal"
                      eslug="agree-privacy-policy-terms-checkbox"
                      scope="default"
                      slug="default"
                      auto
                    />
                  </div>
                  {!component.isPaypal(order) && (
                    <SigninParentGrid
                      container
                      direction="row"
                      justifycontent="center"
                      alignItems="center"
                      spacing={2}
                      style={{ marginTop: 8 }}
                    >
                      <Grid item xs={12}>
                        {component.getData("default.preparing") ? (
                          <Stack direction="row" justifyContent="center">
                            <CircularProgress color="success" />
                          </Stack>
                        ) : (
                          <LoginRegisterBtn
                            type="submit"
                            // disabled={
                            //   !component.getData("default.termsAccepted", false)
                            // }
                            fullWidth
                          >
                            {component.ucfirst("place-order-btn")}
                          </LoginRegisterBtn>
                        )}
                      </Grid>
                    </SigninParentGrid>
                  )}
                </AccordionDetails>
              </form>
            </MyAccordion>
          </Grid>
          {/* order summary */}
          <Grid item md={5} xs={12}>
            <Paper
              square
              elevation={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "10px 25px",
              }}
            >
              <Box style={{ width: "100%" }}>
                <MyTitle variant="h3" gutterBottom>
                  {component.ucfirst("checkout-title-6")}
                </MyTitle>
                {order && order.items.length !== 0 && (
                  <React.Fragment>
                    <List>
                      <Table>
                        <TableHead>
                          <TableRow
                            sx={{
                              borderBottom: "1px solid rgba(0,0,0,.1)",
                              verticalAlign: "top",
                            }}
                          >
                            <MyTableCell
                              sx={{
                                textTransform: "uppercase",
                              }}
                            >
                              {component.trans("item")}
                            </MyTableCell>
                            <MyTableCell
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "table-cell",
                                  md: "none",
                                  lg: "table-cell",
                                },
                                textTransform: "uppercase",
                              }}
                              align="right"
                            >
                              {component.trans("total")}
                            </MyTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.items.map((product, index) => (
                            <ProductCheckoutView
                              key={index}
                              {...{
                                ...props,
                                ...{
                                  product,
                                },
                              }}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </List>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <MyTableCell
                            style={{
                              fontWeight: 500,
                              textTransform: "uppercase",
                            }}
                          >
                            {component.trans("subtotal")}
                          </MyTableCell>
                          <MyTableCell align="right">
                            {component
                              .getApp()
                              .priceFormaterDecimal(
                                devHelper.getObjectValue(order, "totals.items")
                              )}
                          </MyTableCell>
                        </TableRow>
                        {devHelper.getObjectValue(order, "totals.discount") !==
                          0 && (
                          <TableRow>
                            <MyTableCell
                              style={{
                                fontWeight: 500,
                                textTransform: "uppercase",
                              }}
                            >
                              {component.trans("discount")}
                            </MyTableCell>
                            <MyTableCell align="right">
                              {`- ${component
                                .getApp()
                                .priceFormaterDecimal(
                                  devHelper.getObjectValue(
                                    order,
                                    "totals.discount"
                                  )
                                )}`}
                            </MyTableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <MyTableCell
                            style={{
                              fontWeight: 500,
                              textTransform: "uppercase",
                            }}
                          >
                            {component.trans("shipping")}
                          </MyTableCell>
                          <MyTableCell align="right">
                            {component
                              .getApp()
                              .priceFormaterDecimal(
                                devHelper.getObjectValue(
                                  order,
                                  "totals.shipping"
                                )
                              )}
                          </MyTableCell>
                        </TableRow>
                        <TableRow>
                          <MyTableCell
                            sx={{
                              fontWeight: 500,
                              textTransform: "uppercase",
                              pb: 0,
                            }}
                          >
                            {component.trans("taxes")}
                          </MyTableCell>
                          <MyTableCell align="right" sx={{ pb: 0 }}>
                            {component
                              .getApp()
                              .priceFormaterDecimal(
                                devHelper.getObjectValue(order, "totals.taxes")
                              )}
                          </MyTableCell>
                        </TableRow>
                        {devHelper.getObjectValue(order, "taxes") && (
                          <TableRow>
                            <MyTableCell
                              sx={{
                                fontWeight: 500,
                                textTransform: "uppercase",
                                p: 0,
                                fontSize: "14px",
                              }}
                            >
                              {devHelper.getObjectValue(order, "taxes.state")}-
                              {devHelper.getObjectValue(
                                order,
                                "taxes.zip_code"
                              )}
                              -{devHelper.getObjectValue(order, "taxes.cost")}%
                            </MyTableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <MyTableCell
                            sx={{
                              fontWeight: 500,
                              fontSize: "20px",
                              textTransform: "uppercase",
                            }}
                          >
                            {component.trans("total")}
                          </MyTableCell>
                          <MyTableCell
                            sx={{ fontWeight: 500, fontSize: "20px" }}
                            align="right"
                          >
                            {component
                              .getApp()
                              .priceFormaterDecimal(
                                devHelper.getObjectValue(order, "totals.grand")
                              )}
                          </MyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </React.Fragment>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
      <AddressDialog
        {...{
          ...props,
          ...{
            open: newAddressDialogOpen,
            setOpen: (boolean) => setNewAddressDialogOpen(boolean),
            retrieveAddresses: () => component.retrieveAddresses(),
          },
        }}
      />
      <ForgotPasswordDialog
        {...{
          ...props,
          ...{
            open,
            setOpen: (boolean) => setOpen(boolean),
          },
        }}
      />
    </Container>
  );
}
